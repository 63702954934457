import React from 'react';
import ClaimInStoreOfferList from './ClaimInStoreOfferList';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import CssBaseline from '@material-ui/core/CssBaseline';
import ClaimInStoreOffersQuantity from './ClaimInStoreOffersQuantity';

const client = new ApolloClient({ uri: '/graphql' });

export default function InStoreOffersPage() {
  return (
    <div>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Router>
          <Route
            exact
            path="/customers/transactions/:uuid/claim_in_store_offer_quantity"
            component={ClaimInStoreOffersQuantity}
          />
        </Router>
      </ApolloProvider>
    </div>
  );
}
