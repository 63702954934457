import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import AccountHome from './AccountHome';
import CssBaseline from '@material-ui/core/CssBaseline';
import client from '../Client';

export default function AccountRoutes() {
  return (
    <React.Fragment>
      <ApolloProvider client={client}>
        <CssBaseline />
        <Router>
          <Route
            exact
            path="/react/customers/accounts/home"
            component={AccountHome}
          />
        </Router>
      </ApolloProvider>
    </React.Fragment>
  );
}
