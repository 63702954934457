import React from 'react';
import axios from 'axios';

class Coupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: props.coupon,
      active: props.coupon.active,
      boostLabel: 'Touch to PROMOTE'
    };
  }

  toggle() {
    if (this.state.coupon.active) {
      this.state.coupon.active = false;
      this.setState({ coupon: this.state.coupon });
      axios.post(
        `/api/v4/employees/${window.coinout.current_customer.id}/coupons/${this.state.coupon.id}/deactivate`
      );
    } else {
      this.state.coupon.active = true;
      this.setState({ coupon: this.state.coupon });
      axios.post(
        `/api/v4/employees/${window.coinout.current_customer.id}/coupons/${this.state.coupon.id}/activate`
      );
    }
  }

  push() {
    this.setState({ boostLabel: 'PROMOTED' });
    axios.post(
      `/api/v4/employees/${window.coinout.current_customer.id}/coupons/${this.state.coupon.id}/push`
    );
  }

  isActive() {
    if (this.state.coupon.active) {
      return 'coupon active';
    } else {
      return 'coupon inactive';
    }
  }

  render() {
    return (
      <div>
        <div className={this.isActive()}>
          <span className="notify-badge" onClick={this.toggle.bind(this)}>
            ACTIVE
          </span>
          <span
            className="notify-badge-inactive"
            onClick={this.toggle.bind(this)}
          >
            Touch to ACTIVATE
          </span>
          <span className="boost-button" onClick={this.push.bind(this)}>
            {this.state.boostLabel}
          </span>
          <img src={this.state.coupon.original_image_url} />
        </div>
      </div>
    );
  }
}

export default Coupon;
