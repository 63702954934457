import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 150,
    height: 200
  }
}));

export default function Transaction({ transaction, offerId }) {
  const classes = useStyles();

  return (
    <CardActionArea>
      <Link
        href={`/customers/transactions/${transaction.uuid}/claim_in_store_offer_quantity?in_store_offer_id=${offerId}`}
      >
        <Card className={classes.root}>
          <CardMedia
            className={classes.cover}
            image={transaction.thumbnailUrl}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                {transaction.merchantName}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {transaction.createdOn !== undefined && transaction.createdOn}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {transaction.total !== null && `$${transaction.total}`}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </Link>
    </CardActionArea>
  );
}
