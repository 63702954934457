import { gql } from 'apollo-boost';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import InfoArea from '../InfoArea';
import Transaction from '../Transaction';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Loading from '../Loading';
import Error from '../Error';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  textCenter: {
    textAlign: 'center!important'
  }
}));

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

const QuantityButton = ({ classes, uuid, in_store_offer_id, quantity }) => (
  <ListItem button className={classes.listItem}>
    <Button
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      href={`/customers/transactions/${uuid}/claim_in_store_offer?in_store_offer_id=${in_store_offer_id}&quantity=${quantity}`}
      title={`Select quantity ${quantity}`}
    >
      {quantity}
    </Button>
  </ListItem>
);

export default function InStoreOffersShow() {
  const classes = useStyles();

  const { uuid } = useParams();
  const query = useQueryParam();

  return (
    <div className={classes.root}>
      <div className={classes.textCenter}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <InfoArea
              title="How many offers?"
              description="Select the number of offers you are claiming"
              icon={ShoppingCartIcon}
              iconColor="success"
              vertical
            />
          </Grid>
        </Grid>
      </div>

      <List component="nav">
        <QuantityButton
          classes={classes}
          uuid={uuid}
          in_store_offer_id={query.get('in_store_offer_id')}
          quantity={1}
        />
        <QuantityButton
          classes={classes}
          uuid={uuid}
          in_store_offer_id={query.get('in_store_offer_id')}
          quantity={2}
        />
        <QuantityButton
          classes={classes}
          uuid={uuid}
          in_store_offer_id={query.get('in_store_offer_id')}
          quantity={3}
        />
        <QuantityButton
          classes={classes}
          uuid={uuid}
          in_store_offer_id={query.get('in_store_offer_id')}
          quantity={4}
        />
        <QuantityButton
          classes={classes}
          uuid={uuid}
          in_store_offer_id={query.get('in_store_offer_id')}
          quantity={5}
        />
      </List>
    </div>
  );
}
