import React from 'react';
import { InStoreOfferList } from './InStoreOfferList';
import { InStoreOffer } from './InStoreOffer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import CssBaseline from '@material-ui/core/CssBaseline';

const client = new ApolloClient({ uri: '/graphql' });

export default function InStoreOffersPage() {
  return (
    <div>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Router>
          <Route
            exact
            path="/react/customers/in_store_offers"
            component={InStoreOfferList}
          />
          <Route
            exact
            path="/react/customers/in_store_offers/:name"
            component={InStoreOffer}
          />
        </Router>
      </ApolloProvider>
    </div>
  );
}
