import React from 'react';
import Coupon from 'components/Coupon';

class CouponList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { coupons: props.coupons };
  }

  render() {
    return (
      <div class="row">
        {this.state.coupons.map(function(coupon, i) {
          return (
            <div key={i} class="col-xs-12">
              <Coupon coupon={coupon} key={coupon.id} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default CouponList;
