import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoArea from '../InfoArea';
import classNames from 'classnames';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  textCenter: {
    textAlign: 'center!important'
  }
}));

export default function ClaimInStoreOfferSuccess() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.textCenter)}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <InfoArea
            title="Success"
            description="Your bonus is pending."
            icon={CheckBoxIcon}
            iconColor="success"
            vertical
          />
        </Grid>
      </Grid>
    </div>
  );
}
