import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const GET_ACCOUNT_HOME = gql`
  query AccountHome {
    accountHome {
      balance
      withdrawalsTotal
      referralsTotal
      badges {
        name
        imageUrl
      }
    }
  }
`;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    grid: {
      padding: 8
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: 'auto'
    }
  })
);

export default function AccountHome() {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_ACCOUNT_HOME);

  if (loading) {
    return <p>Loading</p>;
  }

  if (error) {
    return <p>Error :( {error.message}</p>;
  }

  const accountHome = data.accountHome;

  const cardBalance = (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h3" align="center">
            ${accountHome.balance}
          </Typography>
          <Typography variant="h6" align="center">
            Balance
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
  const cardLifetime = (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h4" align="center">
            ${accountHome.withdrawalsTotal}
          </Typography>
          <Typography variant="subtitle2" align="center">
            Lifetime payouts
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
  const cardReferrals = (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h4" align="center">
            ${accountHome.referralsTotal}
          </Typography>
          <Typography variant="subtitle2" align="center">
            Referral Bonuses
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
  const cardBadges = (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" align="center">
            Badges
          </Typography>
          <Box padding={2}>
            <Grid container justify="center" spacing={2}>
              {accountHome.badges.map(badge => (
                <Grid item xs={3} alignContent="center">
                  <Avatar
                    src={badge.imageUrl}
                    className={classes.large}
                    title={badge.name}
                  ></Avatar>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Grid container className={classes.grid} spacing={1}>
      <Grid item xs={12}>
        {cardBalance}
      </Grid>
      <Grid item xs={6}>
        {cardLifetime}
      </Grid>
      <Grid item xs={6}>
        {cardReferrals}
      </Grid>
      <Grid item xs={12}>
        {cardBadges}
      </Grid>
    </Grid>
  );
}
