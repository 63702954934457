import React from 'react'
import PhoneNumber from 'components/phone_number'
import CouponList from 'components/CouponList'
import BadgesPage from 'components/BadgesPage'
import InStoreOffersPage from 'components/InStoreOffersPage'
import ClaimInStoreOffersPage from 'components/TransactionInStoreOffers/ClaimInStoreOffersPage'
import ClaimInStoreOfferSuccess from 'components/TransactionInStoreOffers/ClaimInStoreOfferSuccess'
import ClaimInStoreOffersQuantity from 'components/TransactionInStoreOffers/ClaimInStoreOffersQuantity'
import ClaimInStoreOffersQuantityPage from 'components/TransactionInStoreOffers/ClaimInStoreOffersQuantityPage'
import ClaimInStoreOfferError from 'components/TransactionInStoreOffers/ClaimInStoreOfferError'
import InStoreOffersRouter from 'components/InStoreOffers/InStoreOffersRouter'
import AccountRoutes from 'components/accounts/AccountRoutes'
import InfoArea from "../components/InfoArea";
import WebpackerReact from 'webpacker-react'
import Turbolinks from 'turbolinks'

Turbolinks.start();
WebpackerReact.setup({
  PhoneNumber,
  CouponList,
  BadgesPage,
  AccountRoutes,
  InStoreOffersPage,
  ClaimInStoreOffersPage,
  ClaimInStoreOffersQuantity,
  ClaimInStoreOffersQuantityPage,
  ClaimInStoreOfferSuccess,
  ClaimInStoreOfferError,
  InStoreOffersRouter,
  InfoArea
});
