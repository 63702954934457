import { gql } from 'apollo-boost';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import InfoArea from '../InfoArea';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  textCenter: {
    textAlign: 'center!important'
  }
}));

export const QUERY = gql`
  {
    customersInStoreOffers {
      id
      name
      listImageUrl
    }
  }
`;

export default function ClaimInStoreOfferList() {
  const classes = useStyles();

  const { uuid } = useParams();

  const { loading, error, data } = useQuery(QUERY);

  if (loading) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <CircularProgress className={classes.progress} />
              Loading...
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>Error: {error.message}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.textCenter}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <InfoArea
              title="Select In-Store Offer"
              description="Select the in-store offer that applies to your receipt"
              icon={ShoppingCartIcon}
              iconColor="success"
              vertical
            />
          </Grid>
        </Grid>
      </div>
      <List component="nav">
        {data.customersInStoreOffers.map(({ id, name, listImageUrl }) => (
          <ListItem key={name} button className={classes.listItem}>
            <Box width="100%">
              <Card className={classes.card}>
                <a
                  href={`/customers/transactions/${uuid}/claim_in_store_offer_quantity?in_store_offer_id=${id}`}
                  title={name}
                >
                  <CardActionArea>
                    <CardMedia className={classes.media} image={listImageUrl} />
                  </CardActionArea>
                </a>
              </Card>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
