import React from 'react';
import { asYouType } from 'libphonenumber-js';

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    const as_you_type = new asYouType('US');
    var phone = '';

    if (props.phoneNumber) {
      phone = props.phoneNumber.replace(/[^0-9]+/, '', true);
    }

    this.state = { phoneNumber: as_you_type.input(phone) };
  }

  componentDidMount() {
    $('.main-row').addClass('opacity-1');
    $('.main-row').removeClass('opacity-0');
  }

  onKeyDown(event) {
    if (event.keyCode == 8) {
      if (
        this.refs.phoneNumber.selectionStart > 0 &&
        this.refs.phoneNumber.selectionStart ===
          this.refs.phoneNumber.selectionEnd &&
        this.refs.phoneNumber.selectionStart === this.state.phoneNumber.length
      ) {
        this.setState({ phoneNumber: this.state.phoneNumber.slice(0, -1) });
        event.preventDefault();
      }
    } else {
      if (
        event.keyCode >= 48 &&
        event.keyCode <= 57 &&
        this.state.phoneNumber.length >= 14
      ) {
        event.preventDefault();
      }
    }
  }

  onChange(event) {
    const as_you_type = new asYouType('US');
    var phone = '';
    if (event.target.value) {
      phone = event.target.value.replace(/[^0-9]+/, '', true);
      phone = phone.replace(/^0+/, '', true);
    }
    this.setState({ phoneNumber: as_you_type.input(phone) });
  }

  render() {
    return React.createElement('input', {
      onKeyDown: e => this.onKeyDown(e),
      ref: 'phoneNumber',
      value: this.state.phoneNumber,
      onChange: e => this.onChange(e),
      className: 'form-control input-lg text-center',
      id: 'phone_number',
      placeholder: 'Phone Number',
      type: 'tel',
      name: 'customer[phone_number]'
    });
  }
}

export default PhoneNumber;
