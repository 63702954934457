import { gql } from 'apollo-boost';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  image: {
    width: '100%'
  }
}));

export const QUERY = gql`
  query customersInStoreOffer($name: String!) {
    customersInStoreOffer(name: $name) {
      landingImageUrl
    }
  }
`;

export function InStoreOffer({ match }) {
  const classes = useStyles();
  const name = match.params.name;
  const { loading, error, data } = useQuery(QUERY, {
    variables: { name: name }
  });

  if (loading) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <CircularProgress className={classes.progress} />
              Loading...
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>Error: {error.message}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={data.customersInStoreOffer.landingImageUrl}
      />
    </div>
  );
}
