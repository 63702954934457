import { gql } from 'apollo-boost';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  }
}));

export const QUERY = gql`
  {
    customersInStoreOffers {
      name
      listImageUrl
    }
  }
`;

export function InStoreOfferList() {
  const classes = useStyles();

  const { loading, error, data } = useQuery(QUERY);

  if (loading) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <CircularProgress className={classes.progress} />
              Loading...
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        className={classes.spinner}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>Error: {error.message}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <List component="nav">
        {data.customersInStoreOffers.map(({ name, listImageUrl }) => (
          <ListItem key={name} button className={classes.listItem}>
            <Box width="100%">
              <Card className={classes.card}>
                <RouterLink to={`/react/customers/in_store_offers/${name}`}>
                  <CardActionArea>
                    <CardMedia className={classes.media} image={listImageUrl} />
                  </CardActionArea>
                </RouterLink>
              </Card>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
