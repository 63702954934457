import { gql } from 'apollo-boost';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import InfoArea from '../InfoArea';
import Transaction from '../Transaction';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Loading from './Loading';
import Error from './Error';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  textCenter: {
    textAlign: 'center!important'
  }
}));

export const QUERY = gql`
  {
    recentTransactions {
      uuid
      thumbnailUrl
      merchantName
      total
      receiptDate
      createdOn
    }
  }
`;

export default function InStoreOffersShow() {
  const classes = useStyles();

  const { id } = useParams();

  const { loading, error, data } = useQuery(QUERY);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.textCenter}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <InfoArea
              title="Select Receipt"
              description="Select the receipt with the promotional item. You can only claim offers on receipts from last 7 days."
              icon={ShoppingCartIcon}
              iconColor="success"
              vertical
            />
          </Grid>
        </Grid>
      </div>

      <List component="nav">
        {data.recentTransactions.map(transaction => (
          <ListItem key={transaction.uuid} button className={classes.listItem}>
            <Transaction transaction={transaction} offerId={id} />
          </ListItem>
        ))}
        {data.recentTransactions.length === 0 && (
          <ListItem>
            <Box m="auto">
              There are no receipts that have been uploaded in the last 7 days
            </Box>
          </ListItem>
        )}
      </List>
    </div>
  );
}
