import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  progress: {},
  spinner: {
    height: '100vh'
  }
}));

export default function InStoreOffersShow() {
  const classes = useStyles();

  return (
    <Grid container className={classes.spinner} spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <CircularProgress className={classes.progress} />
            <br />
            Loading...
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
