import { gql } from 'apollo-boost';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import InfoArea from '../InfoArea';
import Transaction from '../Transaction';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Loading from './Loading';
import Error from './Error';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  textCenter: {
    textAlign: 'center!important'
  }
}));

export default function InStoreOffersShow() {
  const classes = useStyles();

  const { uuid } = useParams();

  return (
    <div className={classes.root}>
      <div className={classes.textCenter}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <InfoArea
              title="How many offers?"
              description="Select the number of offers you are claiming"
              icon={ShoppingCartIcon}
              iconColor="success"
              vertical
            />
          </Grid>
        </Grid>
      </div>

      <List component="nav">
        <ListItem button className={classes.listItem}>
          <a
            href={`/react/customers/in_store_offers/${uuid}/1/claim`}
            title="Select quantity 1"
          >
            1
          </a>
        </ListItem>
        <ListItem button className={classes.listItem}>
          <a
            href={`/react/customers/in_store_offers/${uuid}/2/claim`}
            title="Select quantity 2"
          >
            2
          </a>
        </ListItem>
        <ListItem button className={classes.listItem}>
          <a
            href={`/react/customers/in_store_offers/${uuid}/3/claim`}
            title="Select quantity 3"
          >
            1
          </a>
        </ListItem>
      </List>
    </div>
  );
}
