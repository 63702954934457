import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ApolloProvider, Query } from 'react-apollo';
import ApolloClient, { gql } from 'apollo-boost';

const BadgeList = lazy(() => import('./BadgeList'));
const Badge = lazy(() => import('./Badge'));
const client = new ApolloClient({ uri: '/graphql' });

class BadgesPage extends React.Component {
  render() {
    return (
      <div>
        <ApolloProvider client={client}>
          <Router>
            <Suspense fallback={<h1>Loading...</h1>}>
              <Route exact path="/react/customers/badges" component={BadgeList} />
              <Route
                exact
                path="/react/customers/badges/:name"
                component={Badge}
              />
            </Suspense>
          </Router>
        </ApolloProvider>
      </div>
    );
  }
}

export default BadgesPage;
