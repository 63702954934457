import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import InfoArea from '../InfoArea';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import { dangerColor } from '../material-kit-pro-react.jsx';

const useStyles = makeStyles(theme => ({
  textCenter: {
    textAlign: 'center!important'
  },
  listItem: {
    color: dangerColor[0],
    justifyContent: 'center',
    fontWeight: 'bold'
  }
}));

export default function ClaimInStoreOfferError({ errors }) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.textCenter)}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <InfoArea
            title="There was a problem:"
            description=""
            icon={ErrorIcon}
            iconColor="danger"
            vertical
          />
        </Grid>
        <Grid item xs={12}>
          <List>
            {errors &&
              errors.map((error, index) => (
                <ListItem key={index} className={classes.listItem}>
                  {error}
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
