import { gql } from 'apollo-boost';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Link from '@material-ui/core/Link';
import Loading from './Loading';
import Error from './Error';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  link: {
    margin: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 1)
  },
  media: {
    paddingBottom: '60%',
    height: 'auto',
    width: '100%'
  },
  progress: {},
  spinner: {
    height: '100vh'
  },
  textCenter: {
    textAlign: 'center!important'
  }
}));

export const QUERY = gql`
  query CustomersInStoreOfferById($id: Int!) {
    customersInStoreOfferById(id: $id) {
      name
      landingImageUrl
    }
  }
`;

export default function InStoreOffersShow() {
  const classes = useStyles();

  const { id } = useParams();

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: parseInt(id) }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.textCenter}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Link href={`/react/customers/in_store_offers/${id}/claim`}>
              <img
                data-testid="landing-image"
                src={data.customersInStoreOfferById.landingImageUrl}
              />
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
