import React from 'react';
import { ApolloProvider } from 'react-apollo';
import CssBaseline from '@material-ui/core/CssBaseline';
import client from '../Client';
import InStoreOffersRoutes from 'components/InStoreOffers/InStoreOffersRoutes';
import { BrowserRouter as Router } from 'react-router-dom';

export default function InStoreOffersRouter() {
  return (
    <div>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Router>
          <InStoreOffersRoutes />
        </Router>
      </ApolloProvider>
    </div>
  );
}
