import React, { Fragment } from 'react';
import InStoreOffersIndex from './InStoreOffersIndex';
import InStoreOffersShow from './InStoreOffersShow';
import InStoreOffersClaim from './InStoreOffersClaim';
import { Route } from 'react-router-dom';
import InStoreOffersQuantity from './InStoreOffersQuantity';

export default function InStoreOffersRoutes() {
  return (
    <Fragment>
      <Route
        exact
        path="/react/customers/in_store_offers"
        component={InStoreOffersIndex}
      />
      <Route
        exact
        path="/react/customers/in_store_offers/:id"
        component={InStoreOffersShow}
      />
      <Route
        exact
        path="/react/customers/in_store_offers/:uuid/quantity"
        component={InStoreOffersQuantity}
      />
      <Route
        exact
        path="/react/customers/in_store_offers/:id/claim"
        component={InStoreOffersClaim}
      />
    </Fragment>
  );
}
