import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  spinner: {
    height: '100vh'
  }
}));

export default function Error({ error }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.spinner} spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <Grid item>Error: {error.message}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
